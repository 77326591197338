import { render, staticRenderFns } from "./StudyAbroad.vue?vue&type=template&id=641f4610&scoped=true&"
import script from "./StudyAbroad.vue?vue&type=script&lang=js&"
export * from "./StudyAbroad.vue?vue&type=script&lang=js&"
import style0 from "./StudyAbroad.vue?vue&type=style&index=0&id=641f4610&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "641f4610",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VCol,VIcon,VRow,VTimeline,VTimelineItem})
